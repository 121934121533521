import {ActivatedRouteSnapshot, Route, Router, RouterStateSnapshot, UrlSegment} from '@angular/router';
import {Injectable} from '@angular/core';
import {AuthenticationService} from '../../../service/authentication.service';
import {select, Store} from '@ngrx/store';
import * as fromRoot from '../../../store/app.reducers';
import {Observable} from 'rxjs';
import {filter, map, take} from 'rxjs/operators';

/**
 * Can be used as a rooter guard to prove whether the user is admin in any channel
 */
@Injectable()
export class AdminGuard {

  constructor(private store: Store<fromRoot.AppState>, private router: Router) {
  }

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(select(fromRoot.getUserState)).pipe(
      // filter out userState objects where userInfo is not yet set
      filter(userState => !!userState.userInfo),
      map(userState => {
        if (AuthenticationService.isAdminForAnyChannel(userState) ||
              AuthenticationService.isSupportOrDevPoi(userState) ||
              userState.isSupportPb) {
          return true;
        } else {
          return false;
        }
      }));
  }

  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  canMatch(route: Route, segments: UrlSegment[]): Observable<boolean> | Promise<boolean> | boolean {
    return this.store.pipe(select(fromRoot.getUserState)).pipe(
      // filter out userState objects where userInfo is not yet set
      filter(userState => !!userState.userInfo),
      take(1),
      map(userState => {
        if (AuthenticationService.isAdminForAnyChannel(userState) ||
              AuthenticationService.isSupportOrDevPoi(userState) ||
              userState.isSupportPb) {
          return true;
        } else {
          return false;
        }
      }));
  }
}
